// 62820
'use strict';
// 100034, 165827
$(document).ready(function () {
    $(document).on('click keyup', '.explore-courses-carousel li a', function (e) {
        $('.product-tiles__list').slick('destroy');
        $('.product-tiles__list').slick({
            dots: false,
            arrows: true,
            slidesToShow: 3,
            infinite: true,
            responsive: [
                {
                    breakpoint: 780,
                    settings: {
                        arrows: true,
                        slidesToShow: 2,
                        infinite: true
                    }
                }
            ]
        });
    });
// 76175
    $('.product-tiles__list').slick({
        dots: false,
        arrows: true,
        slidesToShow: 3,
        infinite: true,
        responsive: [
            {
                breakpoint: 780,
                settings: {
                    arrows: true,
                    slidesToShow: 2,
                    infinite: true
                }
            }
        ]
    });
    $(document).on('afterChange', '.product-tiles__list', function (slick) {
        // 76175
        $('.slick-active').each(function () {
            var curimg = $(this).find('img.lozad');
            if (!$(curimg).hasClass('loaded')) {
                var cdatscr = $(curimg).attr('data-src');
                $(curimg).attr("src", cdatscr);
                $(curimg).parent().removeClass('skeleton-box');
            }
        });
        // 25291 start
        setTimeout(() => {
            $(this).find('.slick-current').find('a').eq(0)
                .focus();
        }, 300);
        // 25291 end
    });
});

$(window).on('load', function () {
    $('.slick-arrow').each(function () {
        $(this).attr('title', $(this).attr('aria-label'));
    });
});
